import { Auth } from "aws-amplify";
import axios from "axios";
import dayjs from "dayjs";
import React, { FC, useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useHistory, useLocation } from "react-router-dom";
import { Breadcrumb, useBreadcrumb } from "../../../components/Breadcrumb";
import { ButtonUI } from "../../../components/Button";
import { FormGroupUI } from "../../../components/FormGroup";
import { FormGroupLabelUI } from "../../../components/FormGroupLabel";
import { LoadingUI } from "../../../components/Loading";
import { PageTitle } from "../../../components/PageTitle";
import { EMAIL_SENDERS, fromOptions } from "../../../constants/emailSenders";
import { toastPromise } from "../../../utils/toastUtils";
import {
  FormValues,
  RadioOptionValue,
} from "../SendBulkEmailsPage/SendBulkEmailsForm";
import { modalStyle } from "./SendBulkEmailsConfirmPageModalStyle";

const EMAILS_ENDPOINT = process.env.REACT_APP_EMAILS_ENDPOINT;

const BREAD_CRUMBS: Breadcrumb[] = [
  { label: "ホーム" },
  { label: "メール一斉配信" },
  { label: "配信内容確認" },
];

const DeliveryTypeBadge: FC<{ formValues: FormValues }> = ({ formValues }) => {
  if (!formValues) {
    return (
      <div className="flex items-center pt-1">
        <strong className="mr-2">配信種別:</strong>
        <span>不明なカテゴリ</span>
      </div>
    );
  }

  const { category } = formValues;
  let content;

  switch (category) {
    case RadioOptionValue.HR:
      content = (
        <div className="flex items-center">
          <span className="badge badge-success text-white pt-[0.12rem]">
            人材
          </span>
          <span className="ml-2 text-sm">(人材を求めている企業に配信する)</span>
        </div>
      );
      break;
    case RadioOptionValue.Matter:
      content = (
        <div className="flex items-center">
          <span className="badge badge-info text-white pt-[0.12rem]">案件</span>
          <span className="ml-2 text-sm">(案件を求めている企業に配信する)</span>
        </div>
      );
      break;
    case RadioOptionValue.HRAndMatter:
      content = (
        <div className="flex items-center">
          <span className="badge badge-accent text-white pt-[0.12rem]">
            人材と案件
          </span>
          <span className="ml-2 text-sm">
            (人材と案件の両方を求めている企業に配信する)
          </span>
        </div>
      );
      break;
    default:
      content = (
        <span className="flex items-center text-sm">不明なカテゴリ</span>
      );
  }

  return (
    <FormGroupUI className="mb-4 flex items-center">
      <FormGroupLabelUI className="w-[3.9rem]">配信種別</FormGroupLabelUI>
      {content}
    </FormGroupUI>
  );
};

const SenderInfo: FC<{ formValues: FormValues | null }> = ({ formValues }) => {
  const sender = fromOptions.find(
    option => option.value === formValues?.from
  ) || { label: "不明な配信者" };

  return (
    <FormGroupUI className="mb-4 flex items-center">
      <FormGroupLabelUI className="w-[3.9rem]">配信者</FormGroupLabelUI>
      <div>{sender.label}</div>
    </FormGroupUI>
  );
};

const ConfirmationEmailInfo: FC<{ formValues: FormValues | null }> = ({
  formValues,
}) => {
  if (!formValues) return null;

  const selectedSender = EMAIL_SENDERS.find(
    sender => sender.email === formValues.from
  );

  return (
    <FormGroupUI className="mb-4 flex items-center">
      <FormGroupLabelUI className="w-[3.9rem]">確認用メール</FormGroupLabelUI>
      <div>
        {formValues.includedMe ? (
          <>
            自身(
            <span className="text-sm">
              {selectedSender?.email ?? "不明な配信者"}
            </span>
            )にも送る
          </>
        ) : (
          "自身に送らない"
        )}
      </div>
    </FormGroupUI>
  );
};

export const SendBulkEmailsConfirmPage: FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const history = useHistory();
  const location = useLocation<{ state: FormValues }>();
  const [formValues, setFormValues] = useState<FormValues | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setBreadcrumbItems(BREAD_CRUMBS);
  }, [setBreadcrumbItems]);

  useEffect(() => {
    if (location.state?.state && !formValues) {
      setFormValues(location.state.state);
    }
  }, [location.state, formValues]);

  const openModal = (test: boolean) => {
    setIsTest(test);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const sendBulkEmails = useCallback(
    async (formValues: FormValues, isTest: boolean) => {
      setIsLoading(true);

      const user = await Auth.currentSession();
      const idToken = user.getIdToken().getJwtToken();
      const { category, ...rest } = formValues;
      const deliveryType = isTest ? "TEST" : category;

      toastPromise(
        axios
          .post(
            EMAILS_ENDPOINT!,
            {
              ...rest,
              deliveryType,
            },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            // NOTE: ユーザーはテスト配信確認後は一括配信の作業を継続したいはずなので、テスト配信後はホーム画面への遷移はせず留まるようにしています。
            if (!isTest) {
              history.push("/");
            }
          })
          .catch(error => {
            console.error(error);
            throw error;
          })
          .finally(() => {
            setIsLoading(false);
          }),
        {
          success: `${isTest ? "テスト" : ""}メールが正常に配信されました！`,
          error: "メールの配信に失敗しました。再試行してください。",
        }
      );
    },
    [history, setIsLoading]
  );

  const handleGoBack = useCallback(() => {
    history.push("/emails/bulk", { state: formValues });
  }, [history, formValues]);

  return (
    <div className="container mx-auto px-4">
      <PageTitle>配信内容確認</PageTitle>
      {isLoading ? (
        <div className="w-full flex flex-col items-center pt-20">
          <LoadingUI type="Grid" />
          <div className="animate-bounce mt-5 font-extrabold">
            {isTest ? "テスト" : "一括"}配信中...
          </div>
        </div>
      ) : (
        <div className="w-3/4">
          {formValues && (
            <>
              <DeliveryTypeBadge formValues={formValues} />
              <SenderInfo formValues={formValues} />
              <ConfirmationEmailInfo formValues={formValues} />
              <FormGroupUI className="mb-4">
                <FormGroupLabelUI className="w-[3.9rem]">件名</FormGroupLabelUI>
                <div className="border-4 rounded p-4 w-full">
                  {formValues.subject ?? ""}
                </div>
              </FormGroupUI>
              <FormGroupUI className="mb-4">
                <FormGroupLabelUI className="w-[3.9rem]">
                  人材Hash
                </FormGroupLabelUI>
                <div className="border-4 rounded p-4 w-full whitespace-pre-wrap">
                  {formValues.hrHash ?? ""}
                </div>
              </FormGroupUI>
              {formValues.scheduled && (
                <FormGroupUI className="mb-4">
                  <FormGroupLabelUI className="w-[3.9rem]">
                    予約配信日時
                  </FormGroupLabelUI>
                  <div className="border-4 rounded p-4 w-full whitespace-pre-wrap">
                    {dayjs(formValues.scheduledAt).format(
                      "YYYY年MM月DD日 HH:mm"
                    ) ?? ""}
                  </div>
                </FormGroupUI>
              )}
              <FormGroupUI className="mb-4">
                <FormGroupLabelUI className="w-[3.9rem] items-start self-start">
                  本文
                </FormGroupLabelUI>
                <div className="border-4 rounded p-4 w-full whitespace-pre-wrap">
                  {formValues.content ?? ""}
                </div>
              </FormGroupUI>
              <div className="flex justify-end mt-2 mb-8 space-x-5">
                <ButtonUI
                  className="w-40"
                  type="button"
                  isOutline={true}
                  onClick={handleGoBack}
                >
                  再編集
                </ButtonUI>
                <ButtonUI
                  className="w-40"
                  type="button"
                  isOutline={true}
                  onClick={() => openModal(true)}
                  disabled={isLoading}
                >
                  テスト配信
                </ButtonUI>
                <ButtonUI
                  className="w-40"
                  type="button"
                  onClick={() => openModal(false)}
                  disabled={isLoading}
                >
                  一斉配信
                </ButtonUI>
              </div>
            </>
          )}
        </div>
      )}
      <ReactModal
        style={modalStyle}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
      >
        <h2 className="text-center font-bold">配信確認</h2>
        <p className="text-center">
          {isTest ? "テスト" : "一斉"}配信します。本当によろしいですか？
        </p>
        <div className="flex justify-center mt-4 space-x-4">
          <ButtonUI
            className="w-32"
            type="button"
            isOutline={true}
            onClick={closeModal}
          >
            キャンセル
          </ButtonUI>
          <ButtonUI
            className="w-32"
            type="button"
            onClick={() => {
              formValues && sendBulkEmails(formValues, isTest);
              closeModal();
            }}
          >
            配信
          </ButtonUI>
        </div>
      </ReactModal>
    </div>
  );
};
